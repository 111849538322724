<script setup lang="ts">
const { data: headerContent } = await useAsyncData('content:components/header', () => queryContent('components/header').findOne())
</script>

<template>
  <UHeader :links="headerContent?.links" :ui="{wrapper: 'lg:!border-transparent bg-gray-50 dark:bg-gray-950'}">
    <template #logo>
      <NuxtImg
        class="w-auto h-6 dark:invert"
        src="/images/shared/kaweah-tech-logo-dark.svg"
        alt="Kaweah Tech"
      />
    </template>

    <template #right>
      <UColorModeButton variant="soft" class="hidden lg:inline-block"/>
    </template>

    <template #panel>
      <UNavigationTree :links="headerContent?.links"/>
      <UDivider class="my-6"/>
      <UColorModeToggle/>
    </template>
  </UHeader>
</template>
