<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <a
    href="#main-content"
    class="absolute left-0 top-0 bg-white dark:bg-black text-black dark:text-white py-2 px-4 z-50 transform -translate-y-full focus:translate-y-0 transition"
  >
    Skip to main content
  </a>
  <AppHeader/>

  <UMain id="main-content" :ui="{ wrapper: '' }">
    <slot/>
  </UMain>

  <AppFooter/>

  <UNotifications/>
</template>
