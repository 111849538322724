<script setup lang="ts">
const { data: footerContent } = await useAsyncData('content:components/footer', () => queryContent('components/footer').findOne())
const { data: sharedContent } = await useAsyncData('content:shared', () => queryContent('shared').findOne())

</script>

<template>
  <UFooter>
    <template #top>
      <UFooterColumns
        :links="footerContent?.links"
        :ui="{
          center: 'xl:col-span-full',
          base: 'text-left'
        }"
      />
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        © {{ new Date().getFullYear() }} Kaweah Tech. All rights reserved.
      </p>
    </template>

    <template #right>
      <UColorModeButton size="sm"/>

      <UButton
        v-for="link in [...sharedContent?.socialLinks, ...sharedContent?.techLinks]"
        :key="link.label"
        :to="link.to"
        :aria-label="link.label"
        :icon="link.icon"
        :external="link.external"
        :target="link.target ?? link.external ? '_blank' : undefined"
        color="gray"
        variant="ghost"
      />
    </template>
  </UFooter>
</template>
